import * as tslib_1 from "tslib";
import { ModalController } from '@ionic/angular';
import { version } from '../../../../package.json';
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;
export class SupportPage {
    constructor(modalController) {
        this.modalController = modalController;
        this.appVersionNumber = version;
    }
    dismissModal() {
        this.modalController.dismiss();
    }
    callForSupport() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // if (this.platform.is('cordova')) {
            //   this.callNumber
            //     .callNumber('+15873346657', true)
            //     .then(res => console.log('Launched dialer!', res))
            //     .catch(err => console.log('Error launching dialer', err));
            // } else {
            yield Browser.open({ url: 'tel:+18322058938' });
            // }
        });
    }
    emailForSupport() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const to = 'support@kavahealth.com';
            const subject = 'Support';
            const body = 'Help needed';
            // if (this.platform.is('cordova')) {
            //   this.composeEmail(to, subject, body);
            // } else {
            yield Browser.open({
                url: 
                // tslint:disable-next-line: max-line-length
                `mailto:${to}?subject=${subject}&body=${body}`
            });
            // }
        });
    }
}
