import { Routes } from '@angular/router';
import { AuthGuard } from './services/auth/auth.guard';
const ɵ0 = () => import("./tabs/tabs.module.ngfactory").then(m => m.TabsPageModuleNgFactory);
const routes = [
    {
        path: 'signin',
        loadChildren: './pages/signin/signin.module#SigninPageModule'
    },
    {
        path: 'signup',
        loadChildren: './pages/signup/signup.module#SignupPageModule'
    },
    {
        path: 'tabs',
        loadChildren: ɵ0,
        canLoad: [AuthGuard]
    },
    {
        path: 'client-profile',
        loadChildren: './pages/client-profile/client-profile.module#ClientProfilePageModule',
        canLoad: [AuthGuard]
    },
    {
        path: 'hospital-details',
        loadChildren: './pages/hospital-details/hospital-details.module#HospitalDetailsPageModule',
        canLoad: [AuthGuard]
    },
    { path: 'terms', loadChildren: './pages/terms/terms.module#TermsPageModule' },
    { path: 'signup-notice', loadChildren: './pages/signup-notice/signup-notice.module#SignupNoticePageModule' },
    { path: 'user-details', loadChildren: './pages/user-details/user-details.module#UserDetailsPageModule', canLoad: [AuthGuard] },
    { path: '**', redirectTo: 'tabs', pathMatch: 'full' },
];
export class AppRoutingModule {
}
export { ɵ0 };
