import * as tslib_1 from "tslib";
import { OnInit, NgZone } from '@angular/core';
import { ModalController, LoadingController, ToastController } from '@ionic/angular';
import { Client, Appointment, LawFirm } from '../../model/appointment';
import * as moment from 'moment';
import { ParseProvider } from '../../services/parse/parse';
import { AuthProvider } from '../../services/auth/auth';
import { AppointmentConfirmationComponent } from '../appointment-confirmation/appointment-confirmation.component';
import { PhoneService } from 'src/app/services/phone/phone.service';
import { NgForm } from '@angular/forms';
import { HospitalPickerComponent } from '../hospital-picker/hospital-picker.component';
import { HospitalService } from '../../services/hospital/hospital.service';
import { Router } from '@angular/router';
import { Hospital } from 'src/app/model/hospital';
export class AppointmentNewComponent {
    constructor(modalController, parseProvider, authProvider, loadCtrl, phoneService, toastController, hospitalProvider, router, zone) {
        this.modalController = modalController;
        this.parseProvider = parseProvider;
        this.authProvider = authProvider;
        this.loadCtrl = loadCtrl;
        this.phoneService = phoneService;
        this.toastController = toastController;
        this.hospitalProvider = hospitalProvider;
        this.router = router;
        this.zone = zone;
        this.familyMemberMode = false;
        this.isReview = false;
        this.lawFirms = [];
        this.hospitals = [];
        this.recentHospitals = [];
        this.compareWith = (o1, o2) => {
            return o1 && o2 ? o1.id === o2.id : o1 === o2;
        };
        this.authProvider.currentUser$().subscribe(user => {
            this.currentUser = user;
            if (this.currentUser) {
                if (this.currentUser.isKavaUser) {
                    this.loadLawFirms();
                }
                else if (this.currentUser.lawFirmGroupId) {
                    this.loadLawFirmGroupClients();
                }
            }
        });
    }
    ngOnInit() {
        this.intitializeData();
    }
    ionViewWillEnter() {
        this.loadRecentHospitals();
        console.log('dob', this.clientToSave.dateOfBirth, this.clientForm.value.dateOfBirth);
    }
    loadRecentHospitals() {
        this.parseProvider
            .getRecentHospitals(this.authProvider.currentUser())
            .then(res => (this.recentHospitals = res.map(x => Hospital.createFromParseObject(x))));
    }
    loadHospitals() {
        this.hospitalProvider.getHospitals().then(res => {
            this.hospitals = res;
        });
    }
    loadLawFirms() {
        this.lawFirms = this.parseProvider.lawFirms;
        // this.parseProvider.getLawForms().then(
        //   res => {
        //     this.lawFirms = res.results.map(x => LawFirm.createFromParseObject(x));
        //   });
    }
    loadLawFirmGroupClients() {
        this.lawFirms = [];
        this.parseProvider.findLawFirmGroup(this.currentUser.lawFirmGroupId).then(res => {
            this.parseProvider.findLawFirmGroupMembers(res).each((client) => this.lawFirms.push(LawFirm.createFromParseObject(client)));
            console.log('lawfirms models', this.lawFirms);
        });
    }
    dismissModal(success) {
        this.modalController.dismiss({ success });
    }
    onPickRecentHospital(hospital) {
        this.appointmentToSave.hospital = hospital;
        this.hospitalPicked = hospital;
    }
    get maxBirthDate() {
        return moment()
            .add(0, 'years')
            .format('YYYY-MM-DD');
    }
    get guardianName() {
        return `${this.clientToSave.firstName} ${this.clientToSave.lastName}`;
    }
    onHospitalIdSelected() {
        this.appointmentToSave.hospital = this.hospitals.find(x => x.id === this.hospitalPicked.id);
    }
    goNext() {
        this.clientToSave.dateOfBirth = moment(this.clientToSave.dateOfBirth).format('YYYY-MM-DD');
        if (this.phoneService.isValidNumber(this.clientToSave.phone)) {
            this.isReview = true;
        }
        else {
            this.presentToast('Client phone number is invalid', false);
        }
    }
    onRevise() {
        this.isReview = false;
    }
    goAddingFamilyMember() {
        this.familyMemberMode = true;
        this.familyMemberToSave = new Client();
        this.familyMemberToSave.dateOfBirth = moment().format('YYYY-MM-DD');
        this.familyMemberToSave.id = null; // identify this is new record.
    }
    goEditingFamilyMember(familyMember) {
        this.familyMemberMode = true;
        this.familyMemberToSave = familyMember;
    }
    onDeleteFamilyMember(familyMember) {
        const index = this.appointmentToSave.familyMembers.indexOf(familyMember);
        if (index !== -1) {
            this.appointmentToSave.familyMembers.splice(index, 1);
        }
    }
    onCancelAddOn() {
        this.familyMemberMode = false;
        this.familyMemberToSave = null;
    }
    confirmToAddFamilyMember() {
        // TODO: save new family member
        this.familyMemberToSave.dateOfBirth = moment(this.familyMemberToSave.dateOfBirth).format('YYYY-MM-DD');
        if (this.familyMemberToSave.id == null) {
            this.familyMemberToSave.id = ''; // wipe out flag
            this.appointmentToSave.familyMembers.push(this.familyMemberToSave);
        }
        if (this.phoneService.isValidNumber(this.clientToSave.phone)) {
            this.familyMemberMode = false;
        }
        else {
            this.presentToast('Phone number is invalid', false);
        }
    }
    complete() {
        // save
        if (this.lawFirmSelected) {
            this.appointmentToSave.lawFirm = this.lawFirmSelected;
        }
        const loader = this.loadCtrl
            .create({
            message: 'Saving...'
        })
            .then(loadingEl => {
            loadingEl.present();
            this.parseProvider.postAppointment(this.appointmentToSave).then(res => {
                this.parseProvider.findAppointment(res.id).then(object => {
                    loadingEl.dismiss();
                    const result = Appointment.createFromParseObject(object);
                    if (this.isModalMode) {
                        this.modalController.dismiss({
                            success: true,
                            result
                        });
                    }
                    else {
                        this.router.navigateByUrl('/tabs/appointments');
                        this.presentAppointmentConfirmationModal(result);
                        this.intitializeData();
                    }
                    //   // let modal = this.modalController.create(AppointmentConfirmationComponent, { data: data }, { cssClass: 'select-modal' });
                    //   this.presentAppointmentConfirmationModal();
                });
            });
        });
    }
    presentToast(message, success = true) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message,
                duration: 2000,
                color: success ? 'success' : 'danger'
            });
            toast.present();
        });
    }
    onClientPhoneNumberChanged(event) {
        if (this.clientToSave.phone && this.clientToSave.phone.length === 10) {
            this.clientToSave.phone = this.formatPhoneNumber(this.clientToSave.phone);
        }
        if (!this.phoneService.isValidNumber(this.clientToSave.phone)) {
            // tslint:disable-next-line: no-string-literal
            this.clientForm.form.controls['phone'].setErrors({ fake: true });
        }
    }
    onAddOnPhoneNumberChanged(event) {
        if (this.familyMemberToSave.phone.length === 10) {
            this.familyMemberToSave.phone = this.formatPhoneNumber(this.familyMemberToSave.phone);
        }
        if (!this.phoneService.isValidNumber(this.familyMemberToSave.phone)) {
            // tslint:disable-next-line: no-string-literal
            this.addOnForm.form.controls['phone'].setErrors({ fake: true });
        }
    }
    formatPhoneNumber(phoneNumberString) {
        return phoneNumberString.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
    }
    presentHospitalPickerModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: HospitalPickerComponent,
                componentProps: { hospitalPicked: this.hospitalPicked }
            });
            modal.onDidDismiss().then(res => {
                if (res.data && res.data.success) {
                    this.hospitalPicked = res.data.hospital;
                    this.appointmentToSave.hospital = res.data.hospital;
                }
            });
            return yield modal.present();
        });
    }
    presentAppointmentConfirmationModal(appointment) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: AppointmentConfirmationComponent,
                componentProps: {
                    appointment
                },
                cssClass: 'appointment-confirmation-modal'
            });
            modal.onDidDismiss().then(res => {
                if (res.data && res.data.keepMakingNewAppointment) {
                    this.modalController.dismiss();
                    this.router.navigateByUrl('/tabs/new-appointment');
                }
                else {
                    this.modalController.dismiss();
                }
            });
            return yield modal.present();
        });
    }
    intitializeData() {
        this.familyMemberMode = false;
        this.isReview = false;
        this.clientToSave = new Client();
        this.clientToSave.dateOfBirth = moment().format('YYYY-MM-DD');
        if (this.clientForm) {
            this.clientForm.resetForm(this.clientToSave);
        }
        this.appointmentToSave = new Appointment();
        this.appointmentToSave.client = this.clientToSave;
        this.appointmentToSave.familyMembers = [];
        this.loadHospitals();
    }
}
