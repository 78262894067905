import { ParseProvider } from '../parse/parse';
import { BehaviorSubject } from 'rxjs';
import { Appointment, AppointmentListResponse, AppointmentsDisplayMode } from 'src/app/model/appointment';
import { AuthProvider } from '../auth/auth';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../parse/parse";
import * as i2 from "../auth/auth";
import * as i3 from "@angular/router";
export class AppointmentService {
    constructor(parseProvider, authProvider, router) {
        this.parseProvider = parseProvider;
        this.authProvider = authProvider;
        this.router = router;
        this.cachedUpcomingList = [];
        this.cachedRecentList = [];
        this.totalUpcomingCount = -1;
        this.totalRecentCount = -1;
        this.totalUpcomingSearchResultCount = 0;
        this.totalRecentSearchResultCount = 0;
        this.searchKeyword = '';
        this.upcomingListSubject = new BehaviorSubject(new AppointmentListResponse());
        this.recentListSubject = new BehaviorSubject(new AppointmentListResponse());
        this.displayMode = new BehaviorSubject(AppointmentsDisplayMode.Upcoming);
        this.authProvider.currentUser$().subscribe(user => {
            this.currentUser = user;
            if (user == null) {
                this.resetData(true);
            }
        });
    }
    get upcomingAppointments() {
        return this.upcomingListSubject.asObservable();
    }
    get recentAppointments() {
        return this.recentListSubject.asObservable();
    }
    get appontmentListDisplayMode() {
        return this.displayMode.asObservable();
    }
    setToUpcomingMode() {
        this.displayMode.next(AppointmentsDisplayMode.Upcoming);
    }
    resetData(resetTotalCounts = false) {
        this.cachedUpcomingList = [];
        this.cachedRecentList = [];
        if (resetTotalCounts) {
            // when search keyword changes, no need to reset those values.
            this.totalUpcomingCount = -1;
            this.totalRecentCount = -1;
        }
        this.totalUpcomingSearchResultCount = 0;
        this.totalRecentSearchResultCount = 0;
        this.searchKeyword = '';
        this.upcomingListSubject.next(new AppointmentListResponse());
        this.recentListSubject.next(new AppointmentListResponse());
    }
    loadData(searchKeyword = null, offset = 0, isUpcomingMode = true, limit = 20) {
        return this.parseProvider
            .getAppointments(this.currentUser, isUpcomingMode, searchKeyword, offset, limit)
            .then(response => {
            if (isUpcomingMode) {
                this.setToUpcomingMode();
            }
            this.searchKeyword = searchKeyword || '';
            if (isUpcomingMode) {
                if (this.searchKeyword.length === 0) {
                    this.totalUpcomingCount = response.totalCount;
                }
                else {
                    this.totalUpcomingSearchResultCount = response.totalCount;
                }
                if (offset === 0) {
                    this.cachedUpcomingList = [];
                }
                this.cachedUpcomingList = [
                    ...this.cachedUpcomingList,
                    ...response.results.map(x => Appointment.createFromParseObject(x))
                ];
                const res = new AppointmentListResponse(response.totalCount, this.cachedUpcomingList);
                this.upcomingListSubject.next(res);
                return res;
            }
            else {
                if (this.searchKeyword.length === 0) {
                    this.totalRecentCount = response.totalCount;
                }
                else {
                    this.totalRecentSearchResultCount =
                        response.totalCountOfAppointmentsToDisplay;
                }
                if (offset === 0) {
                    this.cachedRecentList = [];
                }
                this.cachedRecentList = [
                    ...this.cachedRecentList,
                    ...response.results.map(x => Appointment.createFromParseObject(x))
                ];
                const res = new AppointmentListResponse(response.totalCount, this.cachedRecentList);
                this.recentListSubject.next(res);
                return res;
            }
        }, err => {
            console.error('listappointments err', err);
            if (err.code === 209) {
                // 209 Invalid session token
                this.authProvider.signout().subscribe(res => {
                    console.log('signout', res);
                });
                this.router.navigate(['/signin']);
            }
            return new AppointmentListResponse(0, []);
        });
    }
    checkIn(appointment) {
        return this.parseProvider.checkinAppointment(appointment).then((res) => {
            const appointmentUpdated = Appointment.createFromParseObject(res);
            console.log('appointment checked in', appointmentUpdated);
            this.cachedRecentList = [appointmentUpdated, ...this.cachedRecentList];
            this.recentListSubject.next(new AppointmentListResponse(this.cachedRecentList.length, this.cachedRecentList));
            this.cachedUpcomingList = this.cachedUpcomingList.filter(x => x.id !== appointmentUpdated.id);
            this.upcomingListSubject.next(new AppointmentListResponse(this.cachedUpcomingList.length, this.cachedUpcomingList));
        });
    }
    resendReminder(appointment) {
        return this.parseProvider.resendReminder(appointment);
    }
    cancel(appointment) {
        return this.parseProvider.cancelAppointment(appointment).then((res) => {
            const appointmentUpdated = Appointment.createFromParseObject(res);
            console.log('appointment cancelled', appointmentUpdated);
            this.cachedRecentList = [appointmentUpdated, ...this.cachedRecentList];
            this.recentListSubject.next(new AppointmentListResponse(this.cachedRecentList.length, this.cachedRecentList));
            this.cachedUpcomingList = this.cachedUpcomingList.filter(x => x.id !== appointmentUpdated.id);
            this.upcomingListSubject.next(new AppointmentListResponse(this.cachedUpcomingList.length, this.cachedUpcomingList));
        });
    }
    update(appointment) {
        return this.parseProvider.updateAppointment(appointment).then(res => {
            const index = this.cachedUpcomingList.indexOf(x => x.id === appointment.id);
            if (index > -1) {
                this.cachedUpcomingList = Object.assign([], this.cachedUpcomingList, { indexRecent: appointment });
                this.upcomingListSubject.next(new AppointmentListResponse(this.cachedUpcomingList.length, this.cachedUpcomingList));
            }
        });
    }
}
AppointmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppointmentService_Factory() { return new AppointmentService(i0.ɵɵinject(i1.ParseProvider), i0.ɵɵinject(i2.AuthProvider), i0.ɵɵinject(i3.Router)); }, token: AppointmentService, providedIn: "root" });
