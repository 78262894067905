import * as tslib_1 from "tslib";
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@ionic/angular";
export class UpdateService {
    constructor(swUpdate, toastController) {
        this.swUpdate = swUpdate;
        this.toastController = toastController;
    }
    checkForUpdate() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                console.log('an app update is available');
                if (confirm('New version is available. Load New Version Now?')) {
                    this.updateToLatest();
                }
            });
            // this.swUpdate
            //   .checkForUpdate()
            //   .then(() => {
            //     console.log('Checking for updates...');
            //   })
            //   .catch(err => {
            //     console.error('Error when checking for update', err);
            //   });
        }
    }
    updateToLatest() {
        console.log('Updating to latest version.');
        this.swUpdate.activateUpdate().then(() => {
            this.presentToast(`New version has been installed! Restarting app now...`);
            console.log('New version sucessfully updated, but you might not see this log.');
        });
    }
    presentToast(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message,
                duration: 5000,
                position: 'bottom',
                color: 'danger'
            });
            toast.present();
            toast.onDidDismiss().then(() => {
                // console.log('Dismissed toast');
                window.location.reload();
            });
        });
    }
}
UpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateService_Factory() { return new UpdateService(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.ToastController)); }, token: UpdateService, providedIn: "root" });
