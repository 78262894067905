// Parse
import { Parse } from 'parse';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { AuthProvider } from '../auth/auth';
import { AppointmentStatus, LawFirm } from '../../model/appointment';
import { Router } from '@angular/router';
import { HospitalNetwork } from 'src/app/model/hospital-network';
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth";
import * as i2 from "@angular/router";
// Constants
export class ListResponse {
}
export class ParseProvider {
    constructor(authProvider, router) {
        this.authProvider = authProvider;
        this.router = router;
        this.parseAppId = environment.parseAppId;
        this.parseJsKey = environment.parseJsKey;
        this.parseServerUrl = environment.parseServerUrl;
        this.parseInitialize();
        this.getProviderNetworks();
        this.getLawForms();
    }
    uniqueObjects(array) {
        const result = [];
        const check = new Map();
        for (const item of array) {
            if (!check.has(item.id)) {
                check.set(item.id, true); // set any value to Map
                result.push(item);
            }
        }
        return result.sort((a, b) => (a.name < b.name ? -1 : 1));
    }
    getConfig(configName) {
        return Parse.Config.get().then(config => {
            // console.log(config.get(configName));
            return config.get(configName);
        });
    }
    getObjects(className, offset = 0, limit = 40, queryFilters = null) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const ObjectType = Parse.Object.extend(className);
                let query = new Parse.Query(ObjectType);
                if (queryFilters) {
                    query = queryFilters(query);
                }
                query.count().then(totalCount => {
                    // todo: check offset with total count of object.
                    query.skip(offset);
                    query.limit(limit);
                    console.log('run getObjects querying on ', className);
                    query.find().then(results => {
                        resolve({ totalCount, results });
                    }, error => {
                        console.error(error);
                        reject(error);
                    });
                }, err => {
                    // Unauthrized error, token exired.
                    // logout.
                    if (err.message === 'unauthorized' || err.code === 209) {
                        console.log('logging out');
                        this.authProvider.signout().subscribe();
                        this.router.navigate(['/signin']);
                    }
                });
            }, 0);
        });
    }
    findObject(className, id, includeKeys = null) {
        const query = new Parse.Query(Parse.Object.extend(className));
        query.equalTo('objectId', id);
        if (includeKeys) {
            query.include(includeKeys);
        }
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                query.first().then(result => {
                    resolve(result);
                }, error => {
                    console.error(error);
                    reject(error);
                });
            }, 0);
        });
    }
    parseInitialize() {
        Parse.initialize(this.parseAppId, this.parseJsKey);
        Parse.serverURL = this.parseServerUrl;
    }
    /*
  
  
    */
    findUser(id) {
        return this.findObject(Parse.User, id, ['providerNetwork']);
    }
    findAppointment(id) {
        return this.findObject('Appointment', id, ['client', 'hospital']);
    }
    findHospital(id) {
        return this.findObject('Provider', id, ['network']);
    }
    findLawFirm(id) {
        return this.findObject('LawFirm', id, []);
    }
    findLawFirmGroup(id) {
        return this.findObject('ManagingAggregator', id, []);
    }
    findLawFirmGroupMembers(lawFirmGroupObject) {
        return lawFirmGroupObject.relation('client_firm').query();
    }
    findClient(id) {
        return this.findObject('Client', id, []);
    }
    findUserByPhoneNumber(phone) {
        return this.getObjects(Parse.User, 0, 20, query => {
            query.equalTo('phone', phone);
            query.ascending('createdAt');
            return query;
        }).then(res => res.results[0]);
    }
    findSessionByUser(user) {
        return this.getObjects('_Session', 0, 1, query => {
            query.equalTo('user', user);
            query.ascending('createdAt');
            return query;
        }).then(res => res.results[0]);
    }
    // TODO: move saved data to user table. no need to run query on huge appointment list anymore.
    getRecentHospitals(userContext, limit = 20) {
        return this.getObjects('Appointment', 0, limit, query => {
            const userQuery = new Parse.Query(Parse.User);
            userQuery.equalTo('lastName', userContext.lastName);
            // query.equalTo('createdBy', {
            //   __type: 'Pointer',
            //   className: '_User',
            //   objectId: userContext.id
            // });
            query.matchesQuery('createdBy', userQuery);
            query.descending('createdAt');
            query.include('hospital');
            return query;
        }).then(res => {
            const list = res.results.map(x => x.get('hospital'));
            const set = this.uniqueObjects(list);
            const recent = set.slice(0, 3);
            return new Promise((resolve, reject) => resolve(recent));
        });
    }
    buildQueryMatchesKeyword(className, keyName, keyword) {
        const query = new Parse.Query(Parse.Object.extend(className));
        query.matches(keyName, keyword.trim(), 'i');
        return query;
    }
    getUpcomingAppointments(userContext, searchKeyword = null, offset = 0, limit = 20) {
        return this.getAppointments(userContext, true, searchKeyword, offset, limit);
    }
    getRecentAppointments(userContext, searchKeyword = null, offset = 0, limit = 20) {
        return this.getAppointments(userContext, false, searchKeyword, offset, limit);
    }
    getAppointments(userContext, upcoming = true, searchKeyword = null, offset = 0, limit = 20) {
        return this.getObjects('Appointment', offset, limit, query => {
            query.include(['client', 'hospital', 'lawFirm']);
            if (userContext == null) {
                query.equalTo('objectId', null);
                return query;
            }
            if (searchKeyword && searchKeyword.trim().length > 0) {
                const queries = [
                    this.buildQueryMatchesKeyword('Client', 'firstName', searchKeyword),
                    this.buildQueryMatchesKeyword('Client', 'middleName', searchKeyword),
                    this.buildQueryMatchesKeyword('Client', 'lastName', searchKeyword),
                    this.buildQueryMatchesKeyword('Client', 'phone', searchKeyword)
                ];
                query.matchesQuery('client', Parse.Query.or(...queries));
            }
            console.log('userContext', userContext);
            if (userContext.isKavaUser) {
            }
            else if (userContext.lawFirmGroupObject) {
                // const lawFirmGroupQuery = new Parse.Query(Parse.Object.extend('ManagingAggregator'));
                // lawFirmGroupQuery.equalTo('objectId', userContext.lawFirmGroupId);
                query.matchesQuery('lawFirm', userContext.lawFirmGroupObject.relation('client_firm').query());
            }
            else if (userContext.lawFirmId) {
                const lawFirmQuery = new Parse.Query(Parse.Object.extend('LawFirm'));
                lawFirmQuery.equalTo('objectId', userContext.lawFirmId);
                query.matchesQuery('lawFirm', lawFirmQuery);
            }
            else if (userContext.hospitalId) {
                const hospitalQuery = new Parse.Query(Parse.Object.extend('Provider'));
                hospitalQuery.equalTo('objectId', userContext.hospitalId);
                query.matchesQuery('hospital', hospitalQuery);
            }
            else if (userContext.hospitalNetworkObject) {
                query.matchesQuery('hospital', userContext.hospitalNetworkObject.relation('members').query());
            }
            else {
                query.equalTo('objectId', null); // return nothing to unAuthroized users.
            }
            const twoDaysBefore = moment()
                .add(-2, 'days')
                .toDate();
            if (upcoming) {
                // query.greaterThan('createdAt', twoDaysBefore);
                // query.notEqualTo('status', AppointmentStatus.Cancelled);
                // query.notEqualTo('status', AppointmentStatus.CheckedIn);
                query.notContainedIn('status', [
                    AppointmentStatus.Cancelled,
                    AppointmentStatus.CheckedIn,
                    AppointmentStatus.Expired
                ]);
                query.ascending('createdAt');
            }
            else {
                // query.lessThan('createdAt', twoDaysBefore);
                query.containedIn('status', [
                    AppointmentStatus.Cancelled,
                    AppointmentStatus.CheckedIn,
                    AppointmentStatus.Expired
                ]);
                query.descending('createdAt');
            }
            return query;
        });
    }
    getLawForms() {
        return this.getObjects('LawFirm', 0, 1000, query => {
            query.ascending('name');
            return query;
        }).then(res => {
            this.lawFirms = res.results.map(x => LawFirm.createFromParseObject(x));
        });
    }
    getProviderNetworks() {
        return this.getObjects('ProviderNetwork', 0, 1000)
            .then(res => {
            this.networkPFObjects = res.results;
            this.networks = res.results.map(x => HospitalNetwork.createFromParseObject(x));
        });
    }
    resendReminder(appointment) {
        return Parse.Cloud.run('sendRemindSMS', { appointmentId: appointment.id });
    }
    sendSMS(phone, message) {
        return Parse.Cloud.run('sendSMS', { toNumber: phone, message });
    }
    postUser(userInfo) {
        return Parse.Cloud.run('modifyUser', userInfo);
        // return this.findUser(userInfo.id).then(user => {
        //   // userToSave = user;
        //   // userToSave.set('isKavaUser', userInfo.isKavaUser);
        //   // todo: must do via cloud function
        //   return Parse.Cloud.run('modifyUser', userInfo);
        // });
    }
    postClient(clientInfo) {
        let clientToSave;
        if (clientInfo.id) {
            return this.findClient(clientInfo.id).then(client => {
                clientToSave = client;
                return this.saveClientInfo(clientInfo, clientToSave);
            });
        }
        else {
            const ClientObject = Parse.Object.extend('Client');
            clientToSave = new ClientObject();
            return this.saveClientInfo(clientInfo, clientToSave);
        }
    }
    saveClientInfo(clientInfo, clientToSave) {
        clientToSave.set('firstName', clientInfo.firstName);
        clientToSave.set('lastName', clientInfo.lastName);
        clientToSave.set('phone', clientInfo.phone);
        clientToSave.set('dateOfBirth', clientInfo.dateOfBirth);
        clientToSave.set('canBeContacted', clientInfo.canBeContacted === 'true');
        return clientToSave.save();
    }
    createAppointmentForClient(client, hospitalId, appointmentId, lawFirwId) {
        const AppointmentObject = Parse.Object.extend('Appointment');
        const appointmentToSave = new AppointmentObject();
        if (appointmentId) {
            appointmentToSave.id = appointmentId;
        }
        appointmentToSave.set('createdBy', {
            __type: 'Pointer',
            className: '_User',
            objectId: this.authProvider.currentUser().id
        });
        appointmentToSave.set('hospital', {
            __type: 'Pointer',
            className: 'Provider',
            objectId: hospitalId
        });
        appointmentToSave.set('lawFirm', {
            __type: 'Pointer',
            className: 'LawFirm',
            objectId: lawFirwId || this.authProvider.currentUser().lawFirmId
        });
        appointmentToSave.set('client', {
            __type: 'Pointer',
            className: 'Client',
            objectId: client.id
        });
        return appointmentToSave;
    }
    checkinAppointment(appointmentInfo) {
        return this.findAppointment(appointmentInfo.id).then(appointment => {
            appointment.set('status', AppointmentStatus.CheckedIn);
            appointment.set('checkedInAt', new Date());
            return appointment.save();
        });
    }
    cancelAppointment(appointmentInfo) {
        return this.findAppointment(appointmentInfo.id).then(appointment => {
            appointment.set('status', AppointmentStatus.Cancelled);
            appointment.set('cancelledAt', new Date());
            return appointment.save();
        });
    }
    postAppointment(appointmentInfo) {
        const ClientObject = Parse.Object.extend('Client');
        const clientToSave = new ClientObject();
        clientToSave.set('firstName', appointmentInfo.client.firstName);
        clientToSave.set('lastName', appointmentInfo.client.lastName);
        clientToSave.set('phone', appointmentInfo.client.phone);
        clientToSave.set('dateOfBirth', appointmentInfo.client.dateOfBirth);
        return clientToSave.save().then(client => {
            const appointmentToSave = this.createAppointmentForClient(client, appointmentInfo.hospital.id, appointmentInfo.id, appointmentInfo.lawFirm ? appointmentInfo.lawFirm.id : null);
            appointmentInfo.familyMembers.forEach(familyMember => {
                this.saveAppointmentForEachFamilyMember(familyMember, appointmentInfo.hospital.id, appointmentInfo.id, client.id, appointmentInfo.lawFirm ? appointmentInfo.lawFirm.id : null);
            });
            // all flow should touch the main client appointment save at last.
            // TODO: maybe in the future to merge all object to save into one list?
            return appointmentToSave.save();
        });
    }
    // for now, only hosipital can be changed in appointment
    updateAppointment(appointmentInfo) {
        return this.findAppointment(appointmentInfo.id).then(res => {
            const appointmentToSave = res;
            appointmentToSave.set('status', 'rescheduled');
            appointmentToSave.set('hospital', {
                __type: 'Pointer',
                className: 'Provider',
                objectId: appointmentInfo.hospital.id
            });
            return appointmentToSave.save();
        });
    }
    saveAppointmentForEachFamilyMember(familyMember, hospitalId, appointmentId, guardianId, lawFirmId) {
        const ClientObject = Parse.Object.extend('Client');
        const familyMemberToSave = new ClientObject();
        familyMemberToSave.set('firstName', familyMember.firstName);
        familyMemberToSave.set('lastName', familyMember.lastName);
        familyMemberToSave.set('phone', familyMember.phone);
        familyMemberToSave.set('dateOfBirth', familyMember.dateOfBirth);
        familyMemberToSave.set('canBeContacted', familyMember.canBeContacted === 'true');
        familyMemberToSave.set('guardian', {
            __type: 'Pointer',
            className: 'Client',
            objectId: guardianId
        });
        familyMemberToSave.save().then(addOn => {
            const appointmentForFamilyMember = this.createAppointmentForClient(addOn, hospitalId, appointmentId, lawFirmId);
            return appointmentForFamilyMember.save();
        });
    }
    postHospital(hospitalInfo) {
        let hospitalToSave;
        if (hospitalInfo.id) {
            return this.findHospital(hospitalInfo.id).then(hospital => {
                hospitalToSave = hospital;
                return this.saveHospitalInfo(hospitalInfo, hospitalToSave);
            });
        }
        else {
            const HospitalObject = Parse.Object.extend('Provider');
            hospitalToSave = new HospitalObject();
            return this.saveHospitalInfo(hospitalInfo, hospitalToSave);
        }
    }
    saveHospitalInfo(hospitalInfo, hospitalToSave) {
        console.log(hospitalInfo);
        hospitalToSave.set('name', hospitalInfo.name);
        hospitalToSave.set('street', hospitalInfo.street);
        hospitalToSave.set('city', hospitalInfo.city);
        hospitalToSave.set('state', hospitalInfo.state);
        hospitalToSave.set('zipCode', hospitalInfo.zipCode);
        hospitalToSave.set('phoneNumber', hospitalInfo.phoneNumber);
        const originalNetwork = hospitalToSave.get('network') || { id: null };
        if (hospitalInfo.networkId !== originalNetwork.id) {
            if (hospitalInfo.networkId) {
                hospitalToSave.set('network', {
                    __type: 'Pointer',
                    className: 'ProviderNetwork',
                    objectId: hospitalInfo.networkId
                });
                const newNetwork = this.networkPFObjects.find(n => n.id === hospitalInfo.networkId);
                newNetwork.relation('members').add(hospitalToSave);
                newNetwork.save().then((res) => console.log(res), (e) => console.error(e));
            }
            else {
                hospitalToSave.set('network', null);
                if (originalNetwork.id) {
                    originalNetwork.relation('members').remove(hospitalToSave);
                    originalNetwork.save().then((res) => console.log(res), (e) => console.error(e));
                }
            }
        }
        // TODO: save relationship.
        return hospitalToSave.save();
    }
}
ParseProvider.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParseProvider_Factory() { return new ParseProvider(i0.ɵɵinject(i1.AuthProvider), i0.ɵɵinject(i2.Router)); }, token: ParseProvider, providedIn: "root" });
