import { Platform } from '@ionic/angular';
import { AuthProvider } from './services/auth/auth';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "./services/auth/auth";
export class SplitPane {
    constructor(platform, authProvider) {
        this.platform = platform;
        this.authProvider = authProvider;
        this.splitPaneState = false;
    }
    getSplitPane() {
        if (this.authProvider.currentUser()) {
            if (this.platform.width() > 728) {
                this.splitPaneState = true;
            }
            else {
                this.splitPaneState = false;
            }
        }
        else {
            this.splitPaneState = false;
        }
        return this.splitPaneState;
    }
}
SplitPane.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SplitPane_Factory() { return new SplitPane(i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i2.AuthProvider)); }, token: SplitPane, providedIn: "root" });
