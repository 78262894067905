import * as libphonenumber from 'google-libphonenumber';
import * as i0 from "@angular/core";
export class PhoneService {
    constructor() { }
    isValidNumber(phoneNumber) {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        try {
            const parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, 'US');
            if (phoneUtil.isPossibleNumber(parsedNumber)) {
                return phoneUtil.isValidNumber(parsedNumber);
            }
            return false;
        }
        catch (err) {
            // console.error(err);
            return false;
        }
    }
    format(phoneNumber) {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        return phoneUtil.formatInOriginalFormat(phoneNumber, 'US');
    }
    noExcepetion(func) {
        let ret;
        try {
            ret = func();
        }
        catch (err) {
            ret = 'ERROR';
        }
        finally {
        }
    }
}
PhoneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneService_Factory() { return new PhoneService(); }, token: PhoneService, providedIn: "root" });
