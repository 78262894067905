import { CanLoad, UrlSegment, Router } from '@angular/router';
import { AuthProvider } from './auth';
import * as i0 from "@angular/core";
import * as i1 from "./auth";
import * as i2 from "@angular/router";
export class AuthGuard {
    constructor(authProvider, router) {
        this.authProvider = authProvider;
        this.router = router;
    }
    canLoad(route, segments) {
        console.log('user auth:', this.authProvider.authenticated());
        if (this.authProvider.authenticated()) {
            return true;
        }
        this.router.navigate(['/signin']);
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthProvider), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
