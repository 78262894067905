import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Platform, ModalController, ToastController } from '@ionic/angular';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { version } from '../../package.json';
import { AuthProvider } from './services/auth/auth.js';
import { distinctUntilChanged } from 'rxjs/operators';
import { ParseProvider } from './services/parse/parse.js';
import { SplitPane } from './split-pane.js';
import { AppointmentNewComponent } from './components/appointment-new/appointment-new.component.js';
import { AppointmentService } from './services/appointment/appointment.service.js';
import * as samever from 'semver';
import { environment } from 'src/environments/environment';
import { UpdateService } from './update-service.js';
export class AppComponent {
    constructor(authService, parseProvider, splitPane, modalController, appointmentProvider, platform, toastController, update) {
        this.authService = authService;
        this.parseProvider = parseProvider;
        this.splitPane = splitPane;
        this.modalController = modalController;
        this.appointmentProvider = appointmentProvider;
        this.platform = platform;
        this.toastController = toastController;
        this.update = update;
        this.appVersionNumber = version;
        this.isProd = environment.production;
        this.newVersionAvailable = false;
        this.appointmentPage = {
            title: 'Appointments',
            url: '/tabs/appointments',
            icon: 'bookmark'
        };
        this.profilePage = {
            title: 'Profile',
            url: '/tabs/profile',
            icon: 'person'
        };
        this.supportPage = {
            title: 'Support',
            url: '/tabs/support',
            icon: 'help'
        };
        this.termsPage = {
            title: 'Terms and Conditions',
            url: '/terms',
            icon: 'book'
        };
        this.newUsersPage = {
            title: 'New Users',
            url: '/tabs/users',
            icon: 'flash'
        };
        this.hospitalsPage = {
            title: 'Providers',
            url: '/tabs/hospitals',
            icon: 'business'
        };
        this.userListPage = {
            title: 'User List',
            url: '/tabs/user-list',
            icon: 'contacts'
        };
        this.initializeApp();
    }
    initializeApp() {
        this.platform.ready().then(() => {
            // this.statusBar.styleDefault();
            // this.splashScreen.hide();
            this.checkVersion();
            this.update.checkForUpdate();
        });
    }
    get username() {
        return this.authService.currentUser() ? `${this.authService.currentUser().firstName} ${this.authService.currentUser().lastName}` : null;
    }
    get isLawFirmUser() {
        return this.authService.currentUser() ? this.authService.currentUser().lawFirmId != null : false;
    }
    get userPages() {
        if (this.authService.currentUser()) {
            if (this.authService.currentUser().isKavaUser) {
                return [this.newUsersPage, this.appointmentPage, this.hospitalsPage, this.userListPage];
            }
            return [this.appointmentPage];
        }
        return [];
    }
    ngOnInit() {
        console.log('app start');
        this.authSub = this.authService.userState$
            .pipe(distinctUntilChanged())
            .subscribe(userState => {
            console.log('is user authenticated?', userState);
            // todo: this subscriber doesn't get update?
            // if (userState == null) {
            //   this.appPages = [];
            // } else {
            //   this.appPages = [this.appointmentPage, this.profilePage];
            //   if (userState.isKavaUser) {
            //     this.appPages = [this.newUsersPage, this.hospitalsPage, ...this.appPages];
            //   }
            // }
        });
    }
    onScheduleClient() {
        this.presentNewAppointmentModal();
    }
    presentNewAppointmentModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.appointmentProvider.setToUpcomingMode();
            const modal = yield this.modalController.create({
                component: AppointmentNewComponent,
                componentProps: {}
            });
            modal.onDidDismiss().then(res => {
                if (res.data && res.data.success) {
                    // this.listAppointments();
                    // this.presentAppointmentConfirmationModal(res.data.result);
                }
            });
            return yield modal.present();
        });
    }
    checkVersion() {
        this.parseProvider.getConfig('latestVersion').then(ver => {
            console.log('latest ver:', ver, 'current ver:', this.appVersionNumber);
            if (samever.gt(ver, this.appVersionNumber)) {
                console.log('poping new version alert');
                this.newVersionAvailable = true;
                this.presentToast(`New version ${ver} is available, please refresh browser and wait a few seconds.`);
            }
        });
    }
    reloadPage() {
        window.location.reload();
    }
    presentToast(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message,
                duration: 5000,
                position: 'bottom',
                color: 'danger'
            });
            toast.present();
        });
    }
    ngOnDestroy() {
        if (this.authSub) {
            this.authSub.unsubscribe();
        }
        // Plugins.App.removeListener('appStateChange', this.checkAuthOnResume);
    }
}
