import { ParseProvider } from '../parse/parse';
import { Parse } from 'parse';
import { Hospital } from 'src/app/model/hospital';
import * as i0 from "@angular/core";
import * as i1 from "../parse/parse";
export class HospitalService {
    constructor(parseProvider) {
        this.parseProvider = parseProvider;
    }
    getHospitals() {
        if (this.cachedHospitals) {
            return new Promise((resolve, reject) => resolve(this.cachedHospitals));
        }
        return this.parseProvider.getObjects('Provider', 0, 1000, query => {
            query.ascending('name');
            query.notEqualTo('isDeactivated', true);
            query.include('network');
            return query;
        }).then(res => {
            this.cachedHospitals = res.results.map(x => Hospital.createFromParseObject(x));
            this.totalCount = res.totalCount;
            return this.cachedHospitals;
        });
    }
    sortHospitalsByDistanceTo(clientZipCode) {
        const hospitalZipCodes = this.cachedHospitals.map(x => x.zipCode);
        return Parse.Cloud.run('sortZipCodsByDistance', { clientZipCode, hospitalZipCodes })
            .then(res => {
            return res.data.distances;
            // const distances = res.data.distances;
            // const list = [];
            // for (const zip in distances) {
            //   list.push({ zip, distance: distances[zip] });
            // }
            // return list.sort((a, b) => a.distance - b.distance);
        });
    }
}
HospitalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HospitalService_Factory() { return new HospitalService(i0.ɵɵinject(i1.ParseProvider)); }, token: HospitalService, providedIn: "root" });
